import {displayFormatLinks, inIFrame, isTouchDevice} from "./tools.js";

export function corner_instream_left(id, width, height, refresh, demo) {
    if (id && width && height) {
        //document.body.innerHTML += '<div id="' + id + '"></div>';
        let div;
        if (inIFrame()) {
            div = parent.document.getElementById(id);
        }
        else {
            div = document.getElementById(id);
        }
        if (div) {
            let body;
            let html;
            if (inIFrame()) {
                body = parent.document.body;
                html = parent.document.documentElement;
            }
            else {
                body = document.body;
                html = document.documentElement;
            }
            

            let documentHeight = 0;
            if (inIFrame) {
                documentHeight = window.parent.innerHeight;
            }
            else {
                documentHeight = window.innerHeight;
            }

            div.style.height = height + "px";
            div.style.width = width + "px";
            div.style.left = 0;
            div.style.right = 0;

            div.style.zIndex = 999999;

            div.style.opacity = 1;
            div.style.margin = "0px auto";

            div.style.position = "fixed";
            div.style.left = "20px";
            div.style.right = "";
            div.style.top = ((documentHeight - height) - 20) + "px";

            if (demo) {
                div.style.backgroundColor = "rgb(220,220,220)";
            }

            displayFormatLinks(div);

            id = null;
        }
    }
}