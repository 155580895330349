import {displayFormatLinks, inIFrame, isTouchDevice} from "./tools.js";

export function banner_atf_bottom_over_on_scroll_show(id, width, height,refresh, demo) {
    if (id && width && height) {
        //document.body.innerHTML += '<div id="' + id + '"></div>';
        let div;
        if (inIFrame()) {
            div = parent.document.getElementById(id);
        }
        else {
            div = document.getElementById(id);
        }
        if (div) {
            //div.style.backgroundColor = "red";
            div.style.height = height + "px";
            div.style.width = width + "px";
            div.style.bottom = (height * -1) + "px";
            div.style.left = 0;
            div.style.right = 0;
            div.style.margin = "0px auto";
            div.style.position = "fixed";
            div.style.zIndex = 16777271;

            if (demo) {
                div.style.backgroundColor = "rgb(220,220,220)";
            }

            displayFormatLinks(div);

            if(refresh == false){
                let i = (height * -1);

                //var timeoutId;
                let intervalId;

                setInterval(function () {
                    if (i > (height * -1)) {
                        intervalId = setInterval(function () {
                            if (i > (height * -1)) {
                                i = i - 1;
                                //div.style.height = i + "px";
                                div.style.bottom = i + "px";
                            }
                            else {
                                clearInterval(intervalId);
                            }
                        }, 1, false);
                    }
                }, 2000, false);

                if (inIFrame()) {
                    if (isTouchDevice()) {
                        parent.document.addEventListener("touchmove", e => {
                            clearInterval(intervalId);

                            if (i < 0) {
                                i = i + 20;
                                if (i > 0) {
                                    i = 0;
                                }

                                /*div.style.height = i + "px";
                                div.offsetHeight;*/

                                div.style.bottom = i + "px";
                            }
                        });
                    }
                    else {
                        parent.document.addEventListener('wheel', e => {
                            clearInterval(intervalId);

                            if (i < 0) {
                                i = i + 20;
                                if (i > 0) {
                                    i = 0;
                                }

                                /*div.style.height = i + "px";
                                div.offsetHeight;*/

                                div.style.bottom = i + "px";
                            }
                        });
                    }
                }
                else {
                    if (isTouchDevice()) {
                        document.addEventListener("touchmove", e => {
                            clearInterval(intervalId);

                            if (i < 0) {
                                i = i + 20;
                                if (i > 0) {
                                    i = 0;
                                }

                                /*div.style.height = i + "px";
                                div.offsetHeight;*/

                                div.style.bottom = i + "px";
                            }
                        });
                    }
                    else {
                        document.addEventListener('wheel', e => {
                            clearInterval(intervalId);

                            if (i < 0) {
                                i = i + 20;
                                if (i > 0) {
                                    i = 0;
                                }

                                /*div.style.height = i + "px";
                                div.offsetHeight;*/

                                div.style.bottom = i + "px";
                            }
                        });
                    }
                }
            }

            id = null;
        }
    }
}