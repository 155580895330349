import {displayFormatLinks, inIFrame} from "./tools.js"

export function corner_display (id, width, height, demo) {
    if (id && width && height) {
        let div;
        let videoFrame;
        if (inIFrame()) {
            div = parent.document.getElementById(id);
            videoFrame = parent.document.getElementById(id + "video");
        }
        else {
            div = document.getElementById(id);
            videoFrame = document.getElementById(id + "video");
        }

        if (div) {
            //div.style.backgroundColor = "red";
            div.style.position = "absolute";
            div.style.height = height + "px";
            div.style.width = width + "px";
            div.style.zIndex = 2147483647;

            if (demo) {
                div.style.backgroundColor = "rgb(220,220,220)";
            }

            let divContainer = document.createElement("div");
            
            divContainer.id = id + "container";

            divContainer.height = videoFrame.height;
            divContainer.width = videoFrame.width;

            videoFrame.parentElement.insertBefore(divContainer,videoFrame);
            
            divContainer.append(videoFrame);
            
            //divContainer.append(div);

            /*divContainer.appendChild(videoFrame);
            console.log(div.outerHTML);
            console.log(videoFrame);
            divContainer.appendChild(div);*/

            /*console.log("test");


            console.log("test2");*/

            /*divContainer.style.position="relative";*/


            //videoFrame.contentWindow.document.body.appendChild(div);


                
            
            //videoFrame.parentNode.insertBefore(div,videoFrame);


            //div.style.bottom = divContainer.style.bottom;
            

            //console.log(div.style.bottom);

            displayFormatLinks(div);

            

            id = null;
        }
    }
}