import {displayFormatLinks, inIFrame} from "./tools.js"

export function fixed_bloc (id, width, height, refresh, demo) {
    if (id && width && height) {
        let div;
        if (inIFrame()) {
            div = parent.document.getElementById(id);
        }
        else {
            div = document.getElementById(id);
        }


        if (div) {
            //div.style.backgroundColor = "red";
            div.style.height = height + "px";
            div.style.width = width + "px";
            div.style.position = "relative";

            displayFormatLinks(div);

            if (demo) {
                div.style.backgroundColor = "rgb(220,220,220)";
            }

            id = null;
        }
    }
}