/*import {displayFormatLinks, inIFrame, isTouchDevice} from "./tools.js";

export function banner_atf_top_over(id, width, height, demo) {
    if (id && width && height) {
        //document.body.innerHTML += '<div id="' + id + '"></div>';
        var div;
        if (inIFrame()) {
            div = parent.document.getElementById(id);
            //parent.document.body.appendChild(div);
        }
        else {
            div = document.getElementById(id);
            //document.body.appendChild(div);
        }
        
        if (div) {
            //div.style.backgroundColor = "red";
            div.style.height = height + "px";
            div.style.width = width + "px";
            div.style.left = 0;
            div.style.right = 0;
            div.style.opacity = 1;
            div.style.margin = "0px auto";
            div.style.position = "fixed";
            //div.style.display = "block";
            div.style.top = ((height * 2) * -1) + "px";
            div.style.zIndex = "16777271";

            if (demo) {
                div.style.backgroundColor = "rgb(220,220,220)";
            }

            displayFormatLinks(div);
            
            let timeoutID;
            let touchTimeoutID;
            let i = height * -1;
            let position = "top";
            let executeMouseMove = true;
            let timeoutDownIDs = [];
            let timeoutUpIDs = [];

            function slide(direction) {
                div.style.top = i + "px";
                div.offsetHeight;

                if (direction === "down") {
                    i = i + 1;

                    if (i > 0) {
                        if (timeoutDownIDs.length > 0) {
                            timeoutDownIDs.forEach(t => {
                                clearTimeout(t);
                            });
                        }

                        clearTimeout(timeoutID);
                        executeMouseMove = true;
                        i = 0;
                    }
                    else {
                        timeoutID = setTimeout(slide, 0.10, "down", false);
                        timeoutDownIDs.push(timeoutID);
                    }
                }
                else {
                    i = i - 1;
                    if (i < (height * -1)) {
                        if (timeoutUpIDs.length > 0) {
                            timeoutUpIDs.forEach(t => {
                                clearTimeout(t);
                            });
                        }

                        clearTimeout(timeoutID);
                        executeMouseMove = true;
                        i = height * -1;
                    }
                    else {
                        timeoutID = setTimeout(slide, 1, "up", false);
                        timeoutUpIDs.push(timeoutID);
                    }
                }
            }

            function touchend(e) {
                console.log("touchend");
                clearTimeout(touchTimeoutID);
                executeMouseMove = true;
                //clearTimeout(timeoutID);
                touchTimeoutID = setTimeout(e => {
                    if (timeoutDownIDs.length > 0) {
                        timeoutDownIDs.forEach(t => {
                            clearTimeout(t);
                        });
                    }
                    
                    timeoutID = setTimeout(slide, 1, "up", false);
                    timeoutUpIDs.push(timeoutID);
                }, 2000);
            }

            function mobileDisplayAd(element, e) {
                clearTimeout(touchTimeoutID);
                if (e.touches[e.touches.length - 1].clientY >= height) {
                    if (executeMouseMove === true) {
                        executeMouseMove = false;

                        if (timeoutUpIDs.length > 0) {
                            timeoutUpIDs.forEach(t => {
                                clearTimeout(t);
                            });
                        }

                        timeoutID = setTimeout(slide, 0.5, "down", false);
                        timeoutDownIDs.push(timeoutID);
                    }
                }
            }

            let element;
            if (inIFrame()) {
                if (isTouchDevice()) {
                    element = parent.document;
                    element.addEventListener("touchstart", e => mobileDisplayAd(element, e));
                    element.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                    element.addEventListener("touchend", e => touchend(e));
                }
                else {
                    parent.document.addEventListener('mousemove', e => {
                        if (executeMouseMove === true) {
                            executeMouseMove = false;
                            if (e.clientY <= height) {
                                timeoutID = setTimeout(slide, 0.5, "down", false);
                            }
                            else {
                                timeoutID = setTimeout(slide, 1, "up", false);
                            }
                        }
                    });
                }
                
            }
            else {
                if (isTouchDevice()) {
                    element = parent.document;
                    element.addEventListener("touchstart", e => mobileDisplayAd(element, e));
                    element.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                    element.addEventListener("touchend", e => touchend(e));
                }
                else {
                    document.addEventListener('mousemove', e => {
                        if (executeMouseMove === true) {
                            executeMouseMove = false;
                            if (e.clientY <= height) {
                                timeoutID = setTimeout(slide, 0.5, "down", false);
                            }
                            else {
                                timeoutID = setTimeout(slide, 1, "up", false);
                            }
                        }
                    });
                }
            }
            id = null;
        }
    }
}*/

import {displayFormatLinks, inIFrame, isTouchDevice} from "./tools.js";

export function banner_atf_top_over(id, width, height, refresh, demo) {
    if (id && width && height) {
        //document.body.innerHTML += '<div id="' + id + '"></div>';
        var div;
        if (inIFrame()) {
            div = parent.document.getElementById(id);
            //parent.document.body.appendChild(div);
        }
        else {
            div = document.getElementById(id);
            //document.body.appendChild(div);
        }
        
        if (div) {
            //div.style.backgroundColor = "red";
            div.style.height = height + "px";
            div.style.width = width + "px";
            div.style.left = 0;
            div.style.right = 0;
            div.style.opacity = 1;
            div.style.margin = "0px auto";
            div.style.position = "fixed";
            //div.style.display = "block";
            div.style.top = ((height * 2) * -1) + "px";
            div.style.zIndex = "16777271";

            if (demo) {
                div.style.backgroundColor = "rgb(220,220,220)";
            }

            displayFormatLinks(div);

            if(refresh == false){
                let timeoutID;
                let touchTimeoutID;
                let i = height * -1;
                let position = "top";
                let executeMouseMove = true;
                let timeoutDownIDs = [];
                let timeoutUpIDs = [];

                function slide(direction) {
                    div.style.top = i + "px";
                    div.offsetHeight;

                    if (direction === "down") {
                        //On supprimer le timeout en cours
                        if (timeoutUpIDs.length > 0) {
                            timeoutUpIDs.forEach(t => {
                                clearTimeout(t);
                            });
                        }

                        i = i + 1;

                        if (i > 0) {
                            if (timeoutDownIDs.length > 0) {
                                timeoutDownIDs.forEach(t => {
                                    clearTimeout(t);
                                });
                            }

                            clearTimeout(timeoutID);
                            executeMouseMove = true;
                            i = 0;
                        }
                        else {
                            timeoutID = setTimeout(slide, 0.10, "down", false);
                            timeoutDownIDs.push(timeoutID);
                        }
                    }
                    else {
                        if (timeoutDownIDs.length > 0) {
                            timeoutDownIDs.forEach(t => {
                                clearTimeout(t);
                            });
                        }

                        i = i - 1;
                        if (i < (height * -1)) {
                            if (timeoutUpIDs.length > 0) {
                                timeoutUpIDs.forEach(t => {
                                    clearTimeout(t);
                                });
                            }

                            clearTimeout(timeoutID);
                            executeMouseMove = true;
                            i = height * -1;
                        }
                        else {
                            timeoutID = setTimeout(slide, 1, "up", false);
                            timeoutUpIDs.push(timeoutID);
                        }
                    }
                }

                /* 
                    SlideV2 remplace l'utilisation de setTimeout par setInterval
                */
                let intervalId;
                function slideV2(direction) {
                    if(intervalId != undefined){
                        clearInterval(intervalId);
                    }
                    
                    if (direction === "down") {
                        intervalId = setInterval(()=>{
                            i = i + 1;

                            if (i > 0) {
                                i = 0;
                            }

                            div.style.top = i + "px";
                        },1);
                    }
                    else {
                        intervalId = setInterval(()=>{
                            i = i - 1;
                            if (i < (height * -1)) {
                                i = height * -1;
                            }

                            div.style.top = i + "px";
                        },1);
                    }
                }

                function touchend(e) {
                    clearTimeout(touchTimeoutID);
                    executeMouseMove = true;
                    //clearTimeout(timeoutID);
                    touchTimeoutID = setTimeout(e => {
                        if (timeoutDownIDs.length > 0) {
                            timeoutDownIDs.forEach(t => {
                                clearTimeout(t);
                            });
                        }
                        
                        timeoutID = setTimeout(slide, 1, "up", false);
                        timeoutUpIDs.push(timeoutID);
                    }, 2000);
                }

                function mobileDisplayAd(element, e) {
                    clearTimeout(touchTimeoutID);
                    if (e.touches[e.touches.length - 1].clientY >= height) {
                        if (executeMouseMove === true) {
                            executeMouseMove = false;

                            if (timeoutUpIDs.length > 0) {
                                timeoutUpIDs.forEach(t => {
                                    clearTimeout(t);
                                });
                            }

                            timeoutID = setTimeout(slide, 0.5, "down", false);
                            timeoutDownIDs.push(timeoutID);
                        }
                    }
                }

                let element;
                if (inIFrame()) {
                    if (isTouchDevice()) {
                        element = parent.document;
                        element.addEventListener("touchstart", e => mobileDisplayAd(element, e));
                        element.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                        element.addEventListener("touchend", e => touchend(e));
                    }
                    else {
                        //slideV2("down");

                        parent.document.querySelector("body").addEventListener("mouseleave", (event) => {
                            if(timeoutID != undefined){
                                clearTimeout(timeoutID);
                            }

                            if(intervalId != undefined){
                                clearInterval(intervalId);
                            }
                            
                            slideV2("down");
                        });

                        parent.document.querySelector("body").addEventListener("mouseenter", (event) => {  
                            if(timeoutID != undefined){
                                clearTimeout(timeoutID);
                            }

                            if(intervalId != undefined){
                                clearInterval(intervalId);
                            }

                            timeoutID = setTimeout(()=>{
                                slideV2("up");
                            },2000);
                        });
                    }
                    
                }
                else {
                    if (isTouchDevice()) {
                        element = document;
                        element.addEventListener("touchstart", e => mobileDisplayAd(element, e));
                        element.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                        element.addEventListener("touchend", e => touchend(e));
                    }
                    else {
                        //slideV2("down");

                        document.querySelector("body").addEventListener("mouseleave", (event) => {
                            if(intervalId != undefined){
                                clearInterval(intervalId);
                            }

                            if(timeoutID != undefined){
                                clearTimeout(timeoutID);
                            }
                            
                            slideV2("down");
                        });

                        document.querySelector("body").addEventListener("mouseenter", (event) => {  
                            if(intervalId != undefined){
                                clearInterval(intervalId);
                            }

                            if(timeoutID != undefined){
                                clearTimeout(timeoutID);
                            }

                            timeoutID = setTimeout(()=>{
                                slideV2("up");
                            },2000);
                        });
                    }
                }
            }
            
            id = null;
        }
    }
}