import {loadDirectTag} from "./directTag";
import { isTouchDevice } from "./tools";
import { banner_atf_bottom_over } from "./bannerATFBottomOver.js";
import { banner_atf_bottom_over_on_scroll_hide } from "./bannerATFBottomOverOnScrollHide";
import { banner_atf_bottom_over_on_scroll_show } from "./bannerATFBottomOverOnScrollShow";
import { banner_atf_top_over } from "./bannerATFTopOver";
import { banner_atf_top_push } from "./bannerATFTopPush";
import { banner_atf_bottom_over_fixed } from './bannerATFBottomOverFixed.js';
import { corner_display } from "./cornerDisplay.js";
import { fixed_bloc } from "./fixedBloc";
import { passback_atf_side_left_over_fixed_on_scroll } from "./passbackATFSideLeftOverFixedOnScroll";
import { passback_atf_side_right_over_fixed_on_scroll } from "./passbackATFSideRightOverFixedOnScroll";
import { isConnectedTV, inIFrame, isInViewport } from "./tools";

export function getParamsToLaunchAndLoadDirectTags(tagParams = []){
    let paramsToLaunch = [];
    for (let param of tagParams) {
        if (param.directTag) {
            loadDirectTag(param);
            tagParams = tagParams.filter(function (value) {
                return value.id != param.id;
            });
        } else {
            if (param.theMoneytizerAdUnit) {
                let addParamToLaunch = true;
                for (let paramToLaunch of paramsToLaunch) {
                    if (paramToLaunch.theMoneytizerAdUnit) {
                        if (paramToLaunch.id == param.id) {
                            addParamToLaunch = false;
                            break;
                        }
                    }
                }

                if (addParamToLaunch) {
                    paramsToLaunch.push(param);
                    tagParams = tagParams.filter(function (value) {
                        return value.id != param.id;
                    });
                }
            }
        }
    }
    return paramsToLaunch;
}

export function loadDemoTags(param, tag){
    if (param.formatJsName == "banner_atf_bottom_over_on_scroll_hide") {
        if (isTouchDevice()) {
            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/320x100.jpg"></div>';
        }
        else {
            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/728x90.jpg"></div>';
        }
    }
    else if (param.formatJsName == "banner_atf_top_over") {
        if (isTouchDevice()) {
            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/320x100.jpg"></div>';
        }
        else {
            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/970x250.jpg"></div>';
        }

    }
    else if (param.formatJsName == "banner_atf_bottom_over") {
        if (isTouchDevice()) {
            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/320x100.jpg"></div>';
        }
        else {
            tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/970x250.jpg"></div>';
        }
    }
    else {
        tag.parentNode.innerHTML += '<div id=' + param.id + ' style="width:100%;height:100%;display: flex;justify-content: center;align-items: center;"><img src="' + DEV2PUB_API_URL + '/img/logo-dev2pub.png" style="width: 120px;height:60px;"></div>';
    }

    launchFormat(divId, param.demoHeight, param.demoWidth, param.formatJsName, param.demoMode, false);
}

export function launchFormat(divId, height, width, formatJsName, demoMode, refresh) {
    switch (formatJsName) {
        case "fixed_bloc":
            fixed_bloc(divId, width, height, refresh, demoMode);
            break;
        case "banner_atf_bottom_over_fixed":
            banner_atf_bottom_over_fixed(divId, width, height, refresh, demoMode);
            break;
        case "banner_atf_bottom_over":
            banner_atf_bottom_over(divId, width, height, refresh, demoMode);
            break;
        case "banner_atf_bottom_over_on_scroll_hide":
            banner_atf_bottom_over_on_scroll_hide(divId, width, height, refresh, demoMode);
            break;
        case "banner_atf_bottom_over_on_scroll_show":
            banner_atf_bottom_over_on_scroll_show(divId, width, height, refresh, demoMode);
            break;
        case "banner_atf_top_over":
            banner_atf_top_over(divId, width, height, refresh, demoMode);
            break;
        case "banner_atf_top_push":
            banner_atf_top_push(divId, width, height, refresh, demoMode);
            break;
        case "passback_atf_side_left_over_fixed_on_scroll":
            passback_atf_side_left_over_fixed_on_scroll(divId, width, height, refresh, demoMode);
            break;
        case "passback_atf_side_right_over_fixed_on_scroll":
            passback_atf_side_right_over_fixed_on_scroll(divId, width, height, refresh, demoMode);
            break;
        case "passback_atf_side_left_over_on_scroll_show":
            passback_atf_side_left_over_on_scroll_show(divId, width, height, refresh, demoMode);
            break;
        case "passback_atf_side_right_over_on_scroll_show":
            passback_atf_side_right_over_on_scroll_show(divId, width, height, refresh, demoMode);
            break;
        case "corner_display":
            corner_display(divId, width, height, demoMode);
    }
}

export function addAdUnits(param, divId, adUnits) {
    if (isConnectedTV() == false) {
        let bids = param.theMoneytizerAdUnit.bids;

        let div;
        if (inIFrame()) {
            div = parent.document.getElementById(divId);
        }
        else {
            div = document.getElementById(divId);
        }

        let isInViewPort = isInViewport(div);

        bids.forEach((bid, bidIndex) => {
            if (bid.bidder == "adagio") {
                bid.params.adUnitElementId = divId;

                if (param.formatType != "VIDEO") {
                    bid.params.placement = param.name;
                }
                else {
                    bid.params.placement = param.videoContext;
                }

                if (param.isMobile) {
                    bid.params.environment = "mobile";
                }
                else {
                    bid.params.environment = "desktop";
                }

                bid.params.category = param.category;
            }
            else if (bid.bidder == "rubicon") {
                if (isInViewPort) {
                    bid.params.position = "atf";
                }
                else {
                    bid.params.position = "btf";
                }

                bid.params.keywords = [param.category];

                if (param.name == "Habillage") {
                    bid.params.renderer = {
                        url: 'https://secure-assets.rubiconproject.com/utils/prebidSkin/prebidSkin.js',
                        render: function (prebidBid) {
                            this.push(() => {
                                window.skinOverlay.renderAd({
                                    fullBids: prebidBid,
                                    insertionMarker: "body",
                                    contentWidthMarker: "#11d88c1e-d428-43e7-92e0-4deafee86b16",
                                    insertionType: "ratio",

                                });
                            })
                        }
                    }
                }
            }
            else if (bid.bidder == "appnexus") {
                bid.params.allowSmallerSizes = true;
            }
            else if (bid.bidder == "richaudience") {
                bid.params.supplyType = "site";
            }
            else if (bid.bidder == "adform") {
                bid.params.cdims = param.theMoneytizerAdUnit.sizes;
            }

            param.theMoneytizerAdUnit.bids[bidIndex] = bid;
        });

        let renderer = {};
        if (param.videoContext == "OUTSTREAM") {
            renderer = getOutstreamRenderer();
        }

        let mediaTypes;
        if (param.formatType == "VIDEO") {
            let plcmt = "";
            let placement = "";
            if(param.videoContext == "OUTSTREAM"){
                plcmt = 4
            }
            else{
                plcmt = 1
            }
            mediaTypes = {
                video: {
                    ////////startdelay : 0,
                    context: param.videoContext,
                    ////playerSize: param.theMoneytizerAdUnit.sizes,
                    //playerSize: [640,480],
                    mimes: ['video/mp4', 'video/webm', 'video/x-ms-wmv', 'video/flv', 'application/javascript', 'video/ogg'],
                    ////protocols: [3,6,7,8],
                    //////protocols: [2,3,5,6],
                    ////////protocols: [1,2,3,4,5,6,7,8,9,10,11,12,13,14],
                    protocols: [2, 3, 5, 6, 7, 8],
                    placement: 1,
                    ////api: [1,2,3,4,5],
                    //////api: [1,2],
                    api: [2],
                    minduration: 0,
                    maxduration: 31,
                    linearity: 1,
                    //playerWidth: param.theMoneytizerAdUnit.sizes[0][0] + "px",
                    //playerHeight: param.theMoneytizerAdUnit.sizes[0][1] + "px",
                    playerSize: [param.theMoneytizerAdUnit.sizes[0]],
                    renderer: renderer,
                    playbackmethod: [2],
                    plcmt: plcmt,
                    ////linearity: 1,
                    //minduration: 6,
                    //maxduration: 30,
                    //startdelay: 0,
                    //placement: 1, //1 pour in-stream
                    //playbackmethod: [1,2,3,4,5,6]
                }
            }
        }
        else {
            mediaTypes = {
                banner: {
                    sizes: param.theMoneytizerAdUnit.sizes
                }
            }
        }

        let adUnit = {
            code: param.theMoneytizerAdUnit.code,
            mediaTypes: mediaTypes,
            bids: param.theMoneytizerAdUnit.bids,
            divId: divId,
            idSiteFormat: param.id,
            sizes: param.theMoneytizerAdUnit.sizes,
            floors: {
                currency: 'USD',
                //skipRate: 5,
                modelVersion: 'Ad Unit Floors',
                schema: {
                    fields: ['mediaType']
                },
                values: {
                    'banner': param.bidFloor,
                    'video': param.bidFloor
                },
                default: 0.01
            },
            ortb2Imp: {
                ext: {
                    data: {
                        divId: divId,
                        placement: param.name
                    }
                }
            }
        };

        if (param.formatType == "VIDEO" && param.videoContext == "instream") {
            let videoAdunit1 = { ...adUnit };
            let videoAdunit2 = { ...adUnit };
            let videoAdunit3 = { ...adUnit };

            videoAdunit1.mediaTypes.video.startdelay = 0;
            videoAdunit2.mediaTypes.video.startdelay = 15;
            videoAdunit3.mediaTypes.video.startdelay = 30;

            videoAdunit1.code = videoAdunit1.code + "video1";
            videoAdunit2.code = videoAdunit2.code + "video2";
            videoAdunit3.code = videoAdunit3.code + "video3";

            adUnits.push(videoAdunit1);
            adUnits.push(videoAdunit2);
            adUnits.push(videoAdunit3);
        }
        else {
            adUnits.push(adUnit);
        }
    }

    return adUnits;
}

function getOutstreamRenderer(){
    return {
        url: "https://acdn.adnxs.com/video/outstream/ANOutstreamVideo.js",
        render: function (bid) {
            let ad = bid.ad || bid.vastXml;
            if (ad) {
                var adResponse = {
                    ad: {
                        video: {
                            content: ad,
                            player_height: bid.height,
                            player_width: bid.width,
                        },
                    },
                };
                bid.renderer.push(function () {
                    ANOutstreamVideo.renderAd({
                        targetId: bid.adUnitCode, // target div id to render video.
                        adResponse: adResponse,
                    });
                });
            }
            else {
                if (bid.vastUrl) {
                    (async () => {
                        ad = await fetch(bid.vastUrl).then(resp => resp.text());
                        if (typeof ad === 'string') {
                            var adResponse = {
                                ad: {
                                    video: {
                                        content: ad,
                                        player_height: bid.height,
                                        player_width: bid.width,
                                    },
                                },
                            };
                            bid.renderer.push(function () {
                                ANOutstreamVideo.renderAd({
                                    targetId: bid.adUnitCode, // target div id to render video.
                                    adResponse: adResponse,
                                });
                            });
                        } else {
                            console.log('Invalid VAST');
                        }
                    })();
                } else {
                    console.log('Invalid ad');
                }
            }
        },
    }
}