/*import {displayFormatLinks, inIFrame, isTouchDevice} from "./tools.js"

export function banner_atf_bottom_over(id, width, height,demo) {
        if (id && width && height) {
            //document.body.innerHTML += '<div id="' + id + '"></div>';
            let div;
            if (inIFrame()) {
                div = parent.document.getElementById(id);
            }
            else {
                div = document.getElementById(id);
            }


            if (div) {
                //div.style.backgroundColor = "red";
                div.style.height = height + "px";
                div.style.width = width + "px";
                div.style.left = 0;
                div.style.right = 0;
                div.style.opacity = 1;
                div.style.margin = "0px auto";
                div.style.position = "fixed";
                div.style.bottom = ((height * 2) * -1) + "px";

                if (demo) {
                    div.style.backgroundColor = "rgb(220,220,220)";
                }

                displayFormatLinks(div);

                let timeoutID;
                let touchTimeoutID;
                let i = height * -1;
                let executeMouseMove = true;
                let timeoutDownIDs = [];
                let timeoutUpIDs = [];


                let body;
                let html;
                if (inIFrame()) {
                    body = parent.document.body;
                    html = parent.document.documentElement;
                }
                else {
                    body = document.body;
                    html = document.documentElement;
                }
                

                let documentHeight = 0;
                if (inIFrame) {
                    documentHeight = window.parent.innerHeight;
                }
                else {
                    documentHeight = window.innerHeight;
                }
                function slide(direction) {
                    div.style.bottom = i + "px";

                    if (direction === "down") {
                        i = i + 1;
                        div.offsetHeight;
                        if (i > 0) {
                            if (timeoutDownIDs.length > 0) {
                                timeoutDownIDs.forEach(t => {
                                    clearTimeout(t);
                                });
                            }

                            clearTimeout(timeoutID);
                            executeMouseMove = true;
                            i = 0;
                        }
                        else {
                            timeoutID = setTimeout(slide, 0.5, "down", false);
                            timeoutDownIDs.push(timeoutID);
                        }
                    }
                    else {
                        i = i - 1;
                        if (i < (height * -1)) {
                            if (timeoutUpIDs.length > 0) {
                                timeoutUpIDs.forEach(t => {
                                    clearTimeout(t);
                                });
                            }

                            clearTimeout(timeoutID);
                            executeMouseMove = true;
                            i = height * -1;
                        }
                        else {
                            timeoutID = setTimeout(slide, 1, "up", false);
                            timeoutUpIDs.push(timeoutID);
                        }
                    }
                }

                function touchend(e) {
                    console.log("touchend");
                    clearTimeout(touchTimeoutID);
                    executeMouseMove = true;
                    //clearTimeout(timeoutID);
                    touchTimeoutID = setTimeout(e => {
                        if (timeoutDownIDs.length > 0) {
                            timeoutDownIDs.forEach(t => {
                                clearTimeout(t);
                            });
                        }

                        timeoutID = setTimeout(slide, 1, "up", false);
                        timeoutUpIDs.push(timeoutID);
                    }, 2000);
                }

                function mobileDisplayAd(element, e) {
                    clearTimeout(touchTimeoutID);
                    if (e.touches[e.touches.length - 1].clientY <= (documentHeight - height)) {
                        if (executeMouseMove === true) {
                            executeMouseMove = false;

                            if (timeoutUpIDs.length > 0) {
                                timeoutUpIDs.forEach(t => {
                                    clearTimeout(t);
                                });
                            }

                            timeoutID = setTimeout(slide, 0.5, "down", false);
                            timeoutDownIDs.push(timeoutID);
                        }
                    }
                }

                if (inIFrame()) {
                    if (isTouchDevice()) {
                        element = parent.document;
                        element.addEventListener("touchstart", e => mobileDisplayAd(element, e));
                        element.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                        //el.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                        element.addEventListener("touchend", e => touchend(e));
                    }
                    else {
                        parent.document.addEventListener('mousemove', e => {
                            if (executeMouseMove === true) {
                                executeMouseMove = false;

                                if (e.clientY <= documentHeight && e.clientY >= (documentHeight - height)) {
                                    //console.log(e.clientY);
                                    timeoutID = setTimeout(slide, 0.5, "down", false);
                                }
                                else {
                                    timeoutID = setTimeout(slide, 1, "up", false);
                                }
                            }
                        });
                    }
                }
                else {
                    if (isTouchDevice()) {
                        element = document;
                        element.addEventListener("touchstart", e => mobileDisplayAd(element, e));
                        element.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                        element.addEventListener("touchend", e => touchend(e));
                    }
                    else {
                        document.addEventListener('mousemove', e => {
                            if (executeMouseMove === true) {
                                executeMouseMove = false;

                                if (e.clientY <= documentHeight && e.clientY >= (documentHeight - height)) {
                                    //console.log(e.clientY);
                                    timeoutID = setTimeout(slide, 0.5, "down", false);
                                }
                                else {
                                    timeoutID = setTimeout(slide, 1, "up", false);
                                }
                            }
                        });
                    }
                }

                id = null;
            }
        }
    }*/

    import {displayFormatLinks, inIFrame, isTouchDevice} from "./tools.js"

    export function banner_atf_bottom_over(id, width, height, refresh, demo) {
            if (id && width && height) {
                //document.body.innerHTML += '<div id="' + id + '"></div>';
                let div;
                if (inIFrame()) {
                    div = parent.document.getElementById(id);
                }
                else {
                    div = document.getElementById(id);
                }
    
                if (div) {
                    //div.style.backgroundColor = "red";
                    div.style.height = height + "px";
                    div.style.width = width + "px";
                    div.style.left = 0;
                    div.style.right = 0;
                    div.style.opacity = 1;
                    div.style.margin = "0px auto";
                    div.style.position = "fixed";
                    div.style.bottom = ((height * 2) * -1) + "px";
                    div.style.zIndex = 16777271;

                    if (demo) {
                        div.style.backgroundColor = "rgb(220,220,220)";
                    }
    
                    displayFormatLinks(div);
    
                    if(refresh == false){
                        let timeoutID;
                        let touchTimeoutID;
                        let i = height * -1;
                        let executeMouseMove = true;
                        let timeoutDownIDs = [];
                        let timeoutUpIDs = [];
        
        
                        let body;
                        let html;
                        if (inIFrame()) {
                            body = parent.document.body;
                            html = parent.document.documentElement;
                        }
                        else {
                            body = document.body;
                            html = document.documentElement;
                        }
                        
                        /*let documentHeight = Math.max(body.scrollHeight, body.offsetHeight,
                            html.clientHeight, html.scrollHeight, html.offsetHeight);*/
        
                        let documentHeight = 0;
                        if (inIFrame) {
                            documentHeight = window.parent.innerHeight;
                        }
                        else {
                            documentHeight = window.innerHeight;
                        }

                        let intervalId;
                        function slideV2(direction) {
                            if(intervalId != undefined){
                                clearInterval(intervalId);
                            }

                            if (direction === "down") {
                                intervalId = setInterval(()=>{
                                    i = i + 1;
                                    if (i > 0) {
                                        i = 0;
                                    }
                                    div.style.bottom = i + "px";
                                },1);
                            }
                            else {
                                intervalId = setInterval(()=>{
                                    i = i - 1;
                                    if (i < (height * -1)) {
                                        i = height * -1;
                                    }
                                    div.style.bottom = i + "px";
                                },1);
                            }
                        }

                        function slide(direction) {
                            if (timeoutUpIDs.length > 0) {
                                timeoutUpIDs.forEach(t => {
                                    clearTimeout(t);
                                });
                            }

                            div.style.bottom = i + "px";
        
                            if (direction === "down") {
                                i = i + 1;
                                div.offsetHeight;
                                if (i > 0) {
                                    if (timeoutDownIDs.length > 0) {
                                        timeoutDownIDs.forEach(t => {
                                            clearTimeout(t);
                                        });
                                    }
        
                                    clearTimeout(timeoutID);
                                    executeMouseMove = true;
                                    i = 0;
                                }
                                else {
                                    if(navigator.userAgent.indexOf("Firefox") > -1){
                                        timeoutID = setTimeout(slide, 0.001, "down", false);
                                    }
                                    else{
                                        timeoutID = setTimeout(slide, 0.5, "down", false);
                                    }
                                    
                                    timeoutDownIDs.push(timeoutID);
                                }
                            }
                            else {
                                if (timeoutDownIDs.length > 0) {
                                    timeoutDownIDs.forEach(t => {
                                        clearTimeout(t);
                                    });
                                }

                                i = i - 1;
                                if (i < (height * -1)) {
                                    if (timeoutUpIDs.length > 0) {
                                        timeoutUpIDs.forEach(t => {
                                            clearTimeout(t);
                                        });
                                    }
        
                                    clearTimeout(timeoutID);
                                    executeMouseMove = true;
                                    i = height * -1;
                                }
                                else {
                                    
                                    if(navigator.userAgent.indexOf("Firefox") > -1){
                                        timeoutID = setTimeout(slide, 0.1, "up", false);
                                    }
                                    else{
                                        timeoutID = setTimeout(slide, 1, "up", false);
                                    }
                                    timeoutUpIDs.push(timeoutID);
                                }
                            }
                        }
        
                        function touchend(e) {
                            //console.log("touchend");
                            clearTimeout(touchTimeoutID);
                            executeMouseMove = true;
                            //clearTimeout(timeoutID);
                            touchTimeoutID = setTimeout(e => {
                                if (timeoutDownIDs.length > 0) {
                                    timeoutDownIDs.forEach(t => {
                                        clearTimeout(t);
                                    });
                                }
        
                                timeoutID = setTimeout(slide, 1, "up", false);
                                timeoutUpIDs.push(timeoutID);
                            }, 2000);
                        }
        
                        function mobileDisplayAd(element, e) {
                            clearTimeout(touchTimeoutID);
                            if (e.touches[e.touches.length - 1].clientY <= (documentHeight - height)) {
                                if (executeMouseMove === true) {
                                    executeMouseMove = false;
        
                                    if (timeoutUpIDs.length > 0) {
                                        timeoutUpIDs.forEach(t => {
                                            clearTimeout(t);
                                        });
                                    }
        
                                    timeoutID = setTimeout(slide, 0.5, "down", false);
                                    timeoutDownIDs.push(timeoutID);
                                }
                            }
                        }

                        let element;
                        if (inIFrame()) {
                            if (isTouchDevice()) {
                                element = parent.document;
                                element.addEventListener("touchstart", e => mobileDisplayAd(element, e));
                                element.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                                //el.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                                element.addEventListener("touchend", e => touchend(e));
                            }
                            else {
                                slideV2("down");

                                parent.document.querySelector("body").addEventListener("mouseleave", (event) => {
                                    //timeoutID = setTimeout(slide, 0.5, "down", false);
                                    if(intervalId != undefined){
                                        clearInterval(intervalId);
                                    }

                                    if(timeoutID != undefined){
                                        clearTimeout(timeoutID);
                                    }
                                    
                                    slideV2("down");
                                });
            
                                parent.document.querySelector("body").addEventListener("mouseenter", (event) => {  
                                    //timeoutID = setTimeout(slide, 2000, "up", false);
                                    if(intervalId != undefined){
                                        clearInterval(intervalId);
                                    }

                                    if(timeoutID != undefined){
                                        clearTimeout(timeoutID);
                                    }
            
                                    timeoutID = setTimeout(()=>{
                                        slideV2("up");
                                    },2000);
                                });
                            }
                        }
                        else {
                            if (isTouchDevice()) {
                                element = document;
                                element.addEventListener("touchstart", e => mobileDisplayAd(element, e));
                                element.addEventListener("touchmove", e => mobileDisplayAd(element, e));
                                element.addEventListener("touchend", e => touchend(e));
                            }
                            else {
                                slideV2("down");

                                document.querySelector("body").addEventListener("mouseleave", (event) => {
                                    //timeoutID = setTimeout(slide, 0.5, "down", false);
                                    if(intervalId != undefined){
                                        clearInterval(intervalId);
                                    }

                                    if(timeoutID != undefined){
                                        clearTimeout(timeoutID);
                                    }

                                    slideV2("down");
                                });
            
                                document.querySelector("body").addEventListener("mouseenter", (event) => {  
                                    //timeoutID = setTimeout(slide, 2000, "up", false);
                                    if(intervalId != undefined){
                                        clearInterval(intervalId);
                                    }

                                    if(timeoutID != undefined){
                                        clearTimeout(timeoutID);
                                    }
            
                                    timeoutID = setTimeout(()=>{
                                        slideV2("up");
                                    },2000);
                                });
                            }
                        }
        
                        id = null;
                    }
                }
            }
        }