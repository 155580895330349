import bannerCancel from "./assets/banner-cancel.png";
import bannerCgu from "./assets/banner-cgu.png";

/* Detect if the script is in an iFrame */
export function inIFrame() {
    if ( window.location !== window.parent.location ) {
        return true;
    } else {
        return false;
    }
}

export function isConnectedTV() {
    return (/(smart[-]?tv|OEM, ([a-z0-9_ \-]+), (?:wired|wireless)|(?:Vestel.+VESTEL|VESTEL;)|VOX[;,]|X-VISION[;,]|WELLINGTON[;,]|videoweb|tv2n|Toshiba|TCL|TOKYO[;,]|THOMSON[,]?|THOM|Telefunken|TechnoTrend|Techwood[;,]|TechniSat|Technicolor|TECHNIKA[;,]|TDSystems[;,]|SWTV[;,]|Sony|Smart[;,]|Sky_worth|(?:UMC-)?Sharp|Selevision|SCBC[;,]|SEG[;,]|Samsung|Maple_2011|Salora[;,]|Saba[;,]|PROFILO[;,]|REGAL[;,]|QILIVE[;,]|POLAROID[;,]|Philips|TV|PEAQ|Panasonic|MTK|OK[;,]|Metz|NORDMENDE[;,]|MTC[;,]|MStar[;,]|MIRAY|Medion|MediaTek;|LGE|Manhattan|LUXOR[;,]|Loewe|LINSAR[;,]|Lifemaxx[;,]|LAURUS[;,]|KUBO[;,]|KALLEY[;,]|AFTSO001|JVC[;,]|Inverto|Intek|Ikea|HYUNDAI[;,]|Humax|hdr1000s|HOTEL[;,]|Hitachi[;,]|HORIZON[;,]|Eurofins_Digital_Testing|Hisense|HI-LEVEL[;,]|(?:HHW_)?HAIER|(OWB|Grundig|Arcelik)|GOGEN[;,]|FUEGO[;,]|FU[;,]|FINLUX[;,]|ESSENTIELB[;,]|ELECTRONIA[;,]|DMM|DIGIHOME[;,]|CreNova|Changhong|CELCUS[;,]|BUSH[;,]|Blaupunkt_UMC[;,]|Bangolufsen|ATVIO|ARRIS[;,]|AOC|Altech UEC|Airties|ALDINORD[;,]|ALDISUED[;,]|hbbtv|appletv|googletv|hdmi|netcast\.tv|viera|nettv|roku|\bdtv\b|sonydtv|inettvbrowser|\btv\b)/i).test(navigator.userAgent);
}

/* Detect if we are in touch device */
export function isTouchDevice(){
    /*return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));*/
    
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true;
    }
    else{
        return false;
    }
}

export function displayFormatLinks(div) {
    //Create cross to hide ads (legal rule)
    let divCross = document.createElement('div');
    divCross.style.height = "12px";
    divCross.style.width = "12px";
    divCross.style.position = "absolute";
    divCross.style.opacity = 1;
    //divCross.top = 0;
    divCross.style.bottom = "20px";
    divCross.style.marginBottom = "4px";
    divCross.style.marginRight = "2px";
    divCross.style.right = 0;
    divCross.style.backgroundColor = "white";
    divCross.style.zIndex = "9999999";
    

    let imgCross = document.createElement('img');
    imgCross.src = bannerCancel;
    imgCross.height = 12;
    imgCross.width = 12;
    imgCross.style.display = "block";
    imgCross.addEventListener("click", function () {
        div.style.display = "none";
    });

    divCross.appendChild(imgCross);

    let divCgu = document.createElement('div');
    divCgu.style.height = "15px";
    divCgu.style.width = "15px";
    //divCgu.style.top = "15px";
    divCgu.style.bottom = 0;
    divCgu.style.right = 0;
    divCgu.style.position = "absolute";
    divCgu.style.opacity = 1;
    divCgu.style.backgroundColor = "white";
    //divCgu.top = 0;
    divCgu.style.display = "block";
    divCgu.style.zIndex = "9999999";

    let imgCgu = document.createElement('img');
    imgCgu.src = bannerCgu;
    imgCgu.height = 15;
    imgCgu.width = 15;
    imgCgu.style.display = "block";

    let aCgu = document.createElement('a');
    aCgu.href = "https://dev2pub.com/politique-de-confidentialite/"
    aCgu.appendChild(imgCgu);
    aCgu.target = "_blank";

    divCgu.appendChild(aCgu);

    /*let iFrameDoc = iFrame.contentDocument.body;*/
    /*iFrameDoc.insertBefore(divCross, iFrameDoc.nextSibling);
    iFrameDoc.insertBefore(divCgu, iFrameDoc.nextSibling);*/

    div.appendChild(divCross);
    div.appendChild(divCgu);
}

export function getWidth() {
    return Math.max(
        parent.document.body.scrollWidth,
        parent.document.documentElement.scrollWidth,
        parent.document.body.offsetWidth,
        parent.document.documentElement.offsetWidth,
        parent.document.documentElement.clientWidth
    );
  }
  
export function getHeight() {
return Math.max(
    parent.document.body.scrollHeight,
    parent.document.documentElement.scrollHeight,
    parent.document.body.offsetHeight,
    parent.document.documentElement.offsetHeight,
    parent.document.documentElement.clientHeight
);
}

export function parseQuery(query){
    var Params = new Object();
    if (!query) return Params; // return empty object
    var Pairs = query.split(/[;&]/);
    for (var i = 0; i < Pairs.length; i++) {
        var KeyVal = Pairs[i].split('=');
        if (!KeyVal || KeyVal.length != 2) continue;
        var key = unescape(KeyVal[0]);
        var val = unescape(KeyVal[1]);
        val = val.replace(/\+/g, ' ');
        Params[key] = val;
    }
    return Params;
}

export function isElementVisible(obj) {
    return checkVideoFullScreen();
}

/*export function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    console.log(rect.bottom);
    console.log(rect.right);
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.parent.innerHeight || window.parent.document.documentElement.clientHeight) &&
        rect.right <= (window.parent.innerWidth || window.parent.document.documentElement.clientWidth)
    );
}*/

export function isInViewport(elm) {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(parent.document.documentElement.clientHeight, parent.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

export function checkVideoFullScreen(){
    let videos = parent.document.getElementsByTagName('video') ;
    
    for(let i=0; i < videos.length; i++){
        if(videos[i].width == screen.width && videos[i].height == screen.height){
            return true;
        }
    }

    let iframes = parent.document.getElementsByTagName('iframe');

    for(let i=0; i < iframes.length; i++){
        if(iframes[i].clientWidth >= parent.window.innerWidth && iframes[i].clientHeight >= parent.window.innerHeight){
            return true;
            
        }
    }

    return false;
}

export function uspActive(){
    let uspApi = window.__uspapi || parent.window.__uspapi;
    if(typeof uspApi == 'function'){
        return true;
    }
    else{
        return false;
    }
}

export function rgpdActive(){
    let tcfapi = window.__tcfapi || parent.window.__tcfapi;
    let cmp = window.__cmp || parent.window.__cmp;
    if(typeof tcfapi == 'function' || typeof cmp == 'function'){
        return true;
    }
    else{
        return false;
    }
}

export function loadId5(doc){
  var id5 = {

    /*********** SETUP YOUR CONFIG BELOW ***********/
    
    partnerId : 12, // provided by ID5
    
    cascades : 9, // max number of cascades to support
    
    hasTcfCmp : true, // set to true to call an IAB TCF-compliant CMP
    
    debug : false, // set to true to output debug message (set to false for production)
    
        
    
    /*********** IMPLEMENTATION - DO NOT EDIT ***********/
    
    makeUrl: function() {
    
    var isSync = (typeof this.callType != 'undefined' && this.callType.toLowerCase() === 'sync' && this.myUid && this.myUid.length > 0);
    
    return 'https://id5-sync.com/'+(isSync?'s':'i')+'/'+this.partnerId+'/'+this.cascades+'.gif?'+(isSync?'puid='+encodeURIComponent(this.myUid)+'&':'')+'gdpr='+encodeURIComponent(this.gdprApplies||'')+'&gdpr_consent='+encodeURIComponent(this.consentData||'');
    
    },
    
    prepareId5Pixel: function() {
    
    this.log('Preparing pixel with doc.readyState:', doc.readyState);
    
    if(doc.readyState !== 'loading') {
    
    this.fireId5Pixel();
    
    } else {
    
    doc.addEventListener('DOMContentLoaded', function () {
    
    id5.fireId5Pixel();
    
    });
    
    }
    
    },
    
    fireId5Pixel: function() {
    
    var url = this.makeUrl();
    
    this.log('Firing ID5 pixel at url:', url);
    
    (new Image()).src = url;
    
    },
    
    processTcfResults: function(result) {
    
    this.log('Received TCF result', result);
    
    this.gdprApplies = result.gdprApplies;
    
    this.consentData = result.tcString;
    
    },
    
    init: function() {
    
    if(this.hasTcfCmp){
    
    try {
    
    win.__tcfapi('addEventListener', 2, function(data, success) {
    
    id5.processTcfResults(data);
    
    id5.prepareId5Pixel();
    
    });
    
    } catch (e) {
    
    id5.log('Exception received while calling TCF CMP', e);
    
    id5.prepareId5Pixel();
    
    }
    
    } else {
    
    id5.prepareId5Pixel();
    
    }
    
    },
    
    log: function(msg, data) {
    
    this.debug && console.log('[ID5] '+msg, data);
    
    }
    
    };
    
    id5.init();
}

export function loadCMP(){
    

  var host = 'www.themoneytizer.com';
  var element = document.createElement('script');
  var firstScript = document.getElementsByTagName('script')[0];
  var url = 'https://cmp.quantcast.com'
    .concat('/choice/', '6Fv0cGNfc_bw8', '/', host, '/choice.js');
  var uspTries = 0;
  var uspTriesLimit = 3;
  element.async = true;
  element.type = 'text/javascript';
  element.src = url;

  firstScript.parentNode.insertBefore(element, firstScript);

  function makeStub() {
    var TCF_LOCATOR_NAME = '__tcfapiLocator';
    var queue = [];
    var win = window;
    var cmpFrame;

    function addFrame() {
      var doc = win.document;
      var otherCMP = !!(win.frames[TCF_LOCATOR_NAME]);

      if (!otherCMP) {
        if (doc.body) {
          var iframe = doc.createElement('iframe');

          iframe.style.cssText = 'display:none';
          iframe.name = TCF_LOCATOR_NAME;
          doc.body.appendChild(iframe);
        } else {
          setTimeout(addFrame, 5);
        }
      }
      return !otherCMP;
    }

    function tcfAPIHandler() {
      var gdprApplies;
      var args = arguments;

      if (!args.length) {
        return queue;
      } else if (args[0] === 'setGdprApplies') {
        if (
          args.length > 3 &&
          args[2] === 2 &&
          typeof args[3] === 'boolean'
        ) {
          gdprApplies = args[3];
          if (typeof args[2] === 'function') {
            args[2]('set', true);
          }
        }
      } else if (args[0] === 'ping') {
        var retr = {
          gdprApplies: gdprApplies,
          cmpLoaded: false,
          cmpStatus: 'stub'
        };

        if (typeof args[2] === 'function') {
          args[2](retr);
        }
      } else {
        if(args[0] === 'init' && typeof args[3] === 'object') {
          args[3] = { ...args[3], tag_version: 'V2' };
        }
        queue.push(args);
      }
    }

    function postMessageEventHandler(event) {
      var msgIsString = typeof event.data === 'string';
      var json = {};

      try {
        if (msgIsString) {
          json = JSON.parse(event.data);
        } else {
          json = event.data;
        }
      } catch (ignore) {}

      var payload = json.__tcfapiCall;

      if (payload) {
        window.__tcfapi(
          payload.command,
          payload.version,
          function(retValue, success) {
            var returnMsg = {
              __tcfapiReturn: {
                returnValue: retValue,
                success: success,
                callId: payload.callId
              }
            };
            if (msgIsString) {
              returnMsg = JSON.stringify(returnMsg);
            }
            if (event && event.source && event.source.postMessage) {
              event.source.postMessage(returnMsg, '*');
            }
          },
          payload.parameter
        );
      }
    }

    while (win) {
      try {
        if (win.frames[TCF_LOCATOR_NAME]) {
          cmpFrame = win;
          break;
        }
      } catch (ignore) {}

      if (win === window.top) {
        break;
      }
      win = win.parent;
    }
    if (!cmpFrame) {
      addFrame();
      win.__tcfapi = tcfAPIHandler;
      win.addEventListener('message', postMessageEventHandler, false);
    }
  };

  makeStub();

  var uspStubFunction = function() {
    var arg = arguments;
    if (typeof window.__uspapi !== uspStubFunction) {
      setTimeout(function() {
        if (typeof window.__uspapi !== 'undefined') {
          window.__uspapi.apply(window.__uspapi, arg);
        }
      }, 500);
    }
  };

  var checkIfUspIsReady = function() {
    uspTries++;
    if (window.__uspapi === uspStubFunction && uspTries < uspTriesLimit) {
      console.warn('USP is not accessible');
    } else {
      clearInterval(uspInterval);
    }
  };

  if (typeof window.__uspapi === 'undefined') {
    window.__uspapi = uspStubFunction;
    var uspInterval = setInterval(checkIfUspIsReady, 6000);
  }
}
